<nav aria-label="Page navigation example" *ngIf="shownNumbers.length > 1">
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="shownNumbers[0] === currentPage">
            <span class="page-link" (click)="setTo('first')">««</span>
        </li>

        <li class="page-item" [class.disabled]="currentPage==1">
            <span class="page-link" (click)="addPage(-1)">«</span>
        </li>

        <li class="page-item" *ngFor="let i of shownNumbers" [ngClass]="{ active: i==currentPage }">
            <span class="page-link" *ngIf="i!=currentPage" (click)="setCurrentPage(i)">{{i}}</span>
            <span class="page-link" *ngIf="i==currentPage">
                {{i}}
                <span class="sr-only">(current)</span>
            </span>
        </li>

        <li class="page-item" [class.disabled]="currentPage==numberOfPages">
            <span class="page-link" (click)="addPage(1)">»</span>
        </li>

        <li class="page-item" [class.disabled]="shownNumbers[shownNumbers.length-1] === currentPage">
            <span class="page-link" (click)="setTo('last')">»»</span>
        </li>
    </ul>
</nav>
